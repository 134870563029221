<template>
    <div>
        <q-list bordered class="rounded-borders">
            <q-expansion-item v-for="(section, index) in sections" :key="index" expand-separator  :label="section.label" >
               <q-separator />
                <q-item v-for="(block, i) in section.blocks" :key="i" tag="label" v-ripple>
                    <q-item-section side top>
                        <q-checkbox v-model="block.active" />
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>{{block.label}}</q-item-label>
                    </q-item-section>
                </q-item>
                <q-item class="bg-blue-1" clickable v-ripple>
                     <q-item-section side >
                         <q-icon name="add"></q-icon>
                     </q-item-section>
                     <q-item-section >
                        <!-- <q-select v-model="select.item" :options="select.options" label="Добавить блок" /> -->
                        <!-- <q-btn flat align="left" icon="add" class="full-width" label="Добавить блок" /> -->
                        <q-item-label>Добавить блок</q-item-label>
                    </q-item-section>
                </q-item>
                    
            </q-expansion-item>
        </q-list>
    </div>
</template>

<script>

export default {
    props:[],
    created(){
        // this.$store.commit('tpl_documents/SET_EMPTY_ITEM')
    },
    data () {
        return {
            select: {
                item:'',
                options:[
                    'Блок1','Блок1','Блок1'
                ]
            },
            sections: [
                {
                    label:'1. Раздел документа',
                    blocks:[
                        {
                            label:'1. Блок',
                            active: true,
                        },
                         {
                            label:'2. Блок',
                            active: true,
                        },
                         {
                            label:'3. Блок',
                            active: true,
                        }
                    ]
                },
                {
                    label:'2. Раздел документа',
                    blocks:[
                         {
                            label:'1. Блок',
                            active: true,
                        },
                         {
                            label:'2. Блок',
                            active: true,
                        },
                         {
                            label:'3. Блок',
                            active: true,
                        }
                    ]
                },
                {
                    label:'3. Раздел документа',
                    blocks:[
                         {
                            label:'1. Блок',
                            active: true,
                        },
                         {
                            label:'2. Блок',
                            active: true,
                        },
                         {
                            label:'3. Блок',
                            active: true,
                        }
                    ]
                }
            ]
        }
    },
    computed:{
        // model(){
        //     return this.$store.state.tpl_documents.item
        // },
        // id(){
        //     return this.$store.state.tpl_documents.updateId
        // },
        // options(){
        //     return this.$store.state.tpl_documents.options
        // },
        // showParam(){
        //     if(this.model.type === null) return true
        //     if(this.model.type.id == 1) return true
        //     return false
        // }
    },
    methods:{
        // onDelete(){
        //     this.$store.dispatch('tpl_documents/deleteItem', this.id)
        //     this.$emit('close')
        // },
        // onSubmit(){
        //     if(this.id != null){
        //         this.$store.dispatch('tpl_documents/updateItem', {'id': this.id, 'item': this.model})
        //     }else{
        //         this.$store.dispatch('tpl_documents/addItem', this.model)
        //     }
        //     this.$emit('close')
        // },
        //   typeRule (val) {
        //     if (val === null) {
        //         return ''        
        //     }
        // }
       
    }
    
}
</script>