<template>
    <div class="q-pa-md">
        <q-card class="my-card">
            <q-card-section>
                <div class="text-h6">{{title}}</div>
            </q-card-section>
            <q-separator />
            
            <q-form
                @submit="onSubmit"
                class="q-gutter-md"
            >
            <q-card-section>
                <div class="row q-col-gutter-x-md">
                    <q-input outlined v-model="model.name" label="Имя" class="col-grow col-md-6" dense required hint=""/>
                    <q-select outlined v-model="model.status" bg-color="" :options="options.stats" label="Статус" class="col-grow col-md-3" dense :rules="[typeRule]"/>
                    <q-input outlined v-model="model.info" label="Инфо" class="col-grow col-md-3" dense required hint=""/>
                </div>
                <div class="row q-col-gutter-x-md">
                    <div class="col-md-3">
                      
                      <structure-tpl-documents/>
                      
                    </div>
                    <div class="col-md-9">
                       
                    </div>
                </div>
            </q-card-section>   
            <q-card-section class="q-mt-none">
                <q-btn type="submit" class="bg-primary text-white" label="Сохранить" />
                <q-btn v-if="this.id != null" class="bg-red-5 text-white float-right" label="Удалить" @click="onDelete" />
            </q-card-section>
            </q-form>
        </q-card>
    </div>
</template>

<script>
import StructureTplDocuments from '@/components/templates/documents/structure.vue'
export default {
    components: { StructureTplDocuments },
    data () {
        return {
            title: "Изменить структуру документа",
        }
    },
    created(){
        this.$store.dispatch('catalog_documents/fetchItemById', this.$route.params.id)
    },
    computed:{
        model(){
            return this.$store.state.catalog_documents.item || {}
        },
        id(){
            return this.$store.state.catalog_documents.updateId
        },
        options(){
            return this.$store.state.catalog_documents.options
        },
        statusColor(){
            return val => {return val.id == 1 ? 'green-2': 'red-2'}
        }

    },
    methods: {
        onDelete(){
            this.$store.dispatch('tpl_documents/deleteItem', this.id)
            this.$router.push('/tpl-documents')
        },
        onSubmit(){
            if(this.id != null){
                this.$store.dispatch('tpl_documents/updateItem', {'id': this.id, 'item': this.model})
            }else{
                this.$store.dispatch('tpl_documents/addItem', this.model)
            }
            this.$router.push('/tpl-documents')
        },
        typeRule (val) {
            if (val === null) {
                return ''        
            }
        }
    }
}
</script>